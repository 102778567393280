import React from 'react';
import Main from '../templates/Main';
import design from '../assets/images/storydesign.png';

const Story=({content,first})=>{
    const imageUrl = 'https://mhekha.com/uploads/images/pages/'+content.image;
    return(
        <Main image={imageUrl} imageText={content.short_description} first={first} active="Story" bottomdesign={design} title={content.title} text={content.long_description}/>
    )
}
export default Story;