import React from 'react';
import design from '../assets/images/branddesign.png';
import Main from '../templates/Main';
const Brand=({content,list,first})=>{
    const imageUrl = 'https://mhekha.com/uploads/images/pages/'+content.image;
    return(
        <Main image={imageUrl} bottomdesign={design} link={content.link} linkText={content.short_description} first={first}  active="Brand" title={content.title} text={content.long_description}  list={list}/>

    )

}
export default Brand;   
