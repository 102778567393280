import './App.css';
import {React,useState,useEffect} from 'react';
import Story from './components/Story';
import {Route,Switch} from 'react-router-dom';
import Contact from './components/Contact';
import Brand from './components/Brand';
import axios from 'axios';
import CssLoader from './components/CssLoader/CssLoader';

const config={
  headers:{Authorization: `Bearer 4fJ6f2xi8o4ZMCnPLn0ODg3lEhrMTFFKOouXPl5PO6kD1e1H94OBlvsRhNZR`}
}
function App() {
  const [story, setStory] = useState({})
  const [contact, setContact] = useState({})
  const [brands,setBrands] = useState([]);
  const [first,setFirst]=useState();
  const [loading,setLoading] = useState(true);
  useEffect(() => {
    axios.get('https://mhekha.com/api/all',config)
  .then(
    res=> {
      setStory(res.data.story)
      setContact(res.data.contact)
      setBrands(res.data.brands)
      setFirst(res.data.brands[0].id)
      setLoading(false)
    }
  )
  }, [setStory,setContact,setBrands])
  if (loading){
    return <CssLoader/>
  }
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={()=><Story content={story} first={first}/>}/>
        {/* <Route path="/brand/1" component={()=><Brand content={brands[0]} list={brands}/>}/> */}
        {
          brands.map((brand,index)=>{
          
             return(
               <Route path={`/brand/${brand.id}`} key={index} exact component={()=><Brand content={brand} list={brands} first={brand.id}/>} />
          
             ) 
          })
        }
        <Route path="/contact"  component={()=> <Contact content={contact} first={first}/>}/>
      </Switch>

    </div>
  );
} 

export default App;
