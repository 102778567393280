import React, { useState } from 'react';
import Active from '../components/active';
import logo from '../assets/images/logo.png';
import { NavLink } from 'react-router-dom';
import './DesktopView.css';
import MessageForm from './Form';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import buttonicon from '../assets/images/buttonicon.png';

const DesktopView = ({ image, title, text, active = '', bottomdesign, list, link, linkText, imageText, first }) => {
	let design =
		active === 'Story' ? (
			
				<img src={bottomdesign} className="bottom" alt="indicator" />
			
		) : null;
	let designc =
		active === 'Contact' ? (
			
				<img src={bottomdesign} className="bottom" alt="indicator" />
			
		) : null;
	const [
		show,
		setShow
	] = useState(false);

	let storyText =
		active === 'Story' ? (

			<Fade duration={1500}>
				{/* <Slide bottom duration={1000}> */}
					<div className="banner-text">{imageText}</div>
				{/* </Slide> */}
			</Fade>

		) : null;
	let num=list && list.map(item=>(item.id))
	return (
		<div className="desktop-view">
			<div className="flex" style={{ width: '100%' }}>
				
					<div
						className="imageSection"
						style={{
							backgroundImage :
								'linear-gradient(to bottom,rgba(0,0,0,0.3),rgba(0,0,0,0.3)),' + `url(${image})`
						}}
					/>
				
				<img
					src={logo}
					alt="logo"
					className="logo"
					style={{ width: '125px', height: 'auto', position: 'absolute', left: '3%', top: '5%' }}
				/>
				{storyText}

				<div className="textSection">
					<div className="textNav">
						<div className="d-flex-flex-column align-self-end justify-content-center flex-nowrap">
							<div>{active === 'Story' ? <Active /> : null}</div>
							<div>
								<NavLink to="/" exact activeStyle={{}}>
									Our Story
								</NavLink>
							</div>
						</div>
						<div className="d-flex flex-column align-self-end">
							<div>{active === 'Brand' ? <Active /> : null}</div>
							<div>
								{
									<NavLink to={`/brand/${first}`} exact activeStyle={{}}>
										Our Brands
									</NavLink>
								}
							</div>
						</div>
						<div className="d-flex-flex-column align-self-end">
							<div>{active === 'Contact' ? <Active /> : null}</div>
							<div>
								<NavLink to="/contact" exact activeStyle={{}}>
									Contact
								</NavLink>
							</div>
						</div>
					</div>
					<div className="d-flex flex-column justify-content-center textTitle">
						<Fade duration={1500}>
							<Slide bottom duration={1000}>
								<h2 className="title">{title}</h2>
							</Slide>
						</Fade>
						<Fade duration={1500} delay={500}>
							<Slide bottom duration={1000}>
								<div className="contain">
									<div className="text" dangerouslySetInnerHTML={{ __html: text }} />
								</div>
							</Slide>
						</Fade>
						{active === 'Contact' && (
							<Fade>
								<div className="link-wrap">
									<button className="link" onClick={() => setShow(true)}>
									<img src={buttonicon} style={{margin:'0 6px'}}/>
										Send us a message
									</button>
								</div>
							</Fade>
						)}
						{active === 'Brand' && (
							<Fade duration={2000}>
								<div className="link-wrap">
									<NavLink to={{ pathname: link }} className="smallText link" target={'_blank'}>
									<img src={buttonicon} style={{margin:'0 6px'}}/>

										{linkText}
									</NavLink>
								</div>
							</Fade>
						)}
					</div>
					<div className="d-flex bottomNav justify-content-between flex-wrap brandlist-wrapper">
						{active === 'Brand' &&
							list &&
							list.map((item) => {
								let d =
									title === item.title ? <img src={bottomdesign} style={{ width: '40px' }} /> : null;
								return (
									<div className="d-flex flex-column brand-list">
										<div className="selector">{d}</div>
										<NavLink className="smallText borderTop" to={`/brand/${item.id}`} style={{minHeight:45}}>
										{item.title}
										</NavLink>
									</div>
								);
							})}
					</div>
					{design}
					{designc}
				</div>
			</div>
			<MessageForm show={show} close={() => setShow(false)} />
		</div>
	);
};
export default DesktopView;
