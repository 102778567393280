import React, { useState } from 'react';
import logo from '../assets/images/logo.png';
import * as AiIcons from 'react-icons/ai';
import './MobileView.css';
import * as FaIcons from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import MessageForm from './Form';
import Slide from 'react-reveal/Slide';

import Fade from 'react-reveal/Fade';
import buttonicon from '../assets/images/buttonicon.png';

const MobileView = ({ image, title, text, active = 'Story', bottomdesign, list, link, linkText, imageText, first }) => {
	let design = active === 'Story' ? <img src={bottomdesign} className="bottom-mobile" alt="indicator" /> : null;
	let designc =
		active === 'Contact' ? (
			
				<img src={bottomdesign} className="bottom-mobile" alt="indicator" />
			
		) : null;
	const [
		show,
		setShow
	] = useState(false);
	let imagesText = active === 'Story' ? <Fade duration={1000} delay={500}><div className="banner-text-mobile">{imageText}</div></Fade> : null;

	const [
		sidebar,
		setSidebar
	] = useState(false);
	return (
		<div className="mobile-view">
			<div className="textSection-mobile">
				

				
					<div
						className="bgSection"
						style={{
							backgroundImage :
								'linear-gradient(to bottom,rgba(0,0,0,0.3),rgba(0,0,0,0.3)),' + `url(${image})`
						}}
					><div className="fixed">
					<div className="d-flex justify-content-between align-items-center px-3">
						<div className="logo-small">
							<img src={logo} alt="" />
						</div>
						<div className="navbar">
							<NavLink to="#" className="menu-bars" onClick={() => setSidebar(true)}>
								<FaIcons.FaBars />
							</NavLink>
						</div>
					</div>
					<nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
						<ul className="nav-menu-items">
							<li className="navbar-toggle nav-text">
								<div to="#" className="menu-bars" onClick={() => setSidebar(false)}>
									<AiIcons.AiOutlineClose />
								</div>
							</li>
							<li className="nav-text">
								<NavLink to="/" exact>
									Our Story
								</NavLink>
							</li>
							<li className="nav-text">
								<NavLink to={`/brand/${first}`}>Our Brands</NavLink>
							</li>
							<li className="nav-text">
								<NavLink to="/contact">Contact</NavLink>
							</li>
						</ul>
					</nav>
				</div>
						{imagesText}
					</div>
				
				<div className="content">
					<div className="container">
						<Fade duration={1500}>
							<Slide bottom duration={1000}>
								<div className="mobile-title">{title}</div>
							</Slide>
						</Fade>
						<Fade duration={1500} delay={500}>
							<Slide bottom duration={1000}>
							<div className="mobile-text" dangerouslySetInnerHTML={{ __html: text }} />
						</Slide>
						</Fade>
						{active === 'Contact' && (
							<div className="link-wrap link-wrap-mobile">
								<button className="link" onClick={() => setShow(true)}>
								<img src={buttonicon} style={{margin:'0 6px'}}/> Send us a message
								</button>
							</div>
						)}

						{active === 'Brand' && (
							<div className="link-wrap link-wrap-mobile">
								<NavLink to={{ pathname: link }} className="smallText link" target={'_blank'}>
								<img src={buttonicon} style={{margin:'0 6px'}}/> {linkText}
								</NavLink>
							</div>
						)}
						{design}
					{designc}
					</div>
					
					<div className="d-flex justify-content-between bottomNav-small flex-wrap">
						{active === 'Brand' &&
							list &&
							list.map((item) => {
								let d =
									title === item.title ? <img src={bottomdesign} style={{ width: '40px' }} /> : null;
								return (
									<div className="d-flex flex-column align-self-end ">
										<div>{d}</div>
										<NavLink className="smallText-mobile borderTop" to={`/brand/${item.id}`} style={{minHeight:105}}>
											{item.title}
										</NavLink>
									</div>
								);
							})}
					</div>

					<MessageForm show={show} close={() => setShow(false)} />
				</div>
			</div>
		</div>
	);
};
export default MobileView;
