import React, { useState } from 'react';
import axios from 'axios';
const MessageForm = (props) => {
	const [
		name,
		setName
	] = useState('');
	const [
		email,
		setEmail
	] = useState('');
	const [
		message,
		setMessage
	] = useState('');
	const [status,setStatus]=useState('');
	const [button, setButton]=useState(false);
    const handleSubmit=(e)=>{
        e.preventDefault();
		setButton(true)
        const data ={name,email,message};
        axios.post('https://mhekha.com/api/send-message',data).then(res=>{
			console.log(res.data)
			if (res.data.status_type ==="success"){
					setEmail('');
					setName('');
					setMessage('');
					
				}
				setButton(false)
				setStatus(res.data.status_message)
			})
			setStatus('')
        
    }
	const closeModal=()=>{
		props.close();
		setStatus('');
	}
	return (
		<div className={`modals ${props.show ? 'show' : ''}`} onClick={props.close}>
			<div className="modals-content" onClick={(e) => e.stopPropagation()}>
				<div className="modals-header d-flex justify-content-between">
					<h4 className="modals-title">SEND US A MESSAGE</h4>
					<p onClick={()=>closeModal()} style={{cursor:'pointer'}}>X</p>
				</div>
				<form onSubmit={handleSubmit}>
					<div className="modals-body">
						<div className="form-group mb-3">
							
							<input
								required
								type="text"
								className="form-control"
								value={name}
								placeholder="Enter your full name"
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						<div className="form-group mb-3">
							
							<input
								required
								type="email"
								className="form-control"
								placeholder="Enter your email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className="form-group mb-3">
							
							<textarea required className="form-control" id="message" placeholder="Enter your message" rows="3" value={message} onChange={(e)=>setMessage(e.target.value)} />
						</div>
					</div>
					<div className="modals-footer d-flex justify-content-between">
						<p>{status}</p>
						<button className="link" type="submit" disabled={button}>Send</button>
					</div>
				</form>
			</div>
		</div>
	);
};
export default MessageForm;
