import React from 'react';
import '../App.css';
import DesktopView from './DesktopView';
import MobileView from './MobileView';

const Main = ({ image, title, text, active, bottomdesign,list,link,linkText,imageText,first }) => {
	
	return (
		<>
		
			<DesktopView  image={image} title={title} first={first} text={text} active={active} bottomdesign={bottomdesign} list={list} link={link} linkText={linkText} imageText={imageText}/>
	
			<MobileView className="mobile-view" image={image}  first={first}  title={title} text={text} active={active} bottomdesign={bottomdesign} list={list} link={link} linkText={linkText} imageText={imageText}/>
		
		</>
	);
};
export default Main;
